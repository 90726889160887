import {
	Text,
	Img,
	Heading,
	Box,
	SimpleGrid,
	Flex,
	Button,
	Link,
} from "@chakra-ui/react";

import React from "react";

const information = [
	{
		title: "ASIA Private Equity Forum 2025",
		link: "http://apef.hkvca.com.hk",
		cover:
			"https://static.levocdn.com/WGLBUT82/BannerSuppIVCA-01-hjB8vGX7kWYW.png",
		btnText: "Register Now",
		desc: (
			<>
				<Text fontWeight={600} variant="featureText" textAlign="justify">
					APEF is a leading annual industry event in the region, organized by
					the Hong Kong Venture Capital and Private Equity Association (HKVCA),
					Asia's oldest and largest private equity industry group. The event
					will take place on January 15, 2025, during the Hong Kong government's
					high-profile international financial week and immediately following
					the Asian Financial Forum. The focus of the discussions will be on
					Asian private equity, particularly in China, Japan, India and
					Southeast Asia, covering topics such as family offices, private
					credit, venture capital, sector-focused funds, and regulation.
				</Text>
				<Text
					my="8px"
					fontWeight={800}
					variant="featureText"
					textAlign="justify"
				>
					Location: Hong Kong
				</Text>
				<Text variant="featureText" fontWeight={800} textAlign="justify">
					IVCA members can enjoy 20% discount for registration.
				</Text>
			</>
		),
	},
	//   {
	//     title: 'Private Investment Funds Webinar Series 2024',
	//     link: 'https://www.morganlewis.com/events/asia-private-investment-funds-webinar-series',
	//     cover: 'https://static.levocdn.com/WGLBUT82/BannerMorganLewis-OaJSLBL3g4Jw.jpg',
	//     btnText: 'Register Now',
	//     desc: (
	//       <>
	//         <Text fontWeight={800} variant="featureText" textAlign="justify">
	//           Please note that the webinar is restricted to Alternative Asset Funds
	//           only.
	//         </Text>
	//         <Text variant="featureText" textAlign="justify">
	//           Location: Online
	//         </Text>
	//       </>
	//     ),
	//   },
	//   {
	//     title: 'The world of private capital meets in Asia',
	//     link: 'https://informaconnect.com/superreturnasia/',
	//     cover: '/jpeg/SuperReturn.jpeg',
	//     btnText: 'Register Now',
	//     desc: (
	//       <Text variant="featureText" textAlign="justify">
	//         <Text as="span" textAlign="left">
	//           Location: Marina Bay Sands Convention Centre Singapore.
	//         </Text>
	//         <br />
	//         Partner Promo Code: FKR3538IVCA
	//       </Text>
	//     ),
	//   },
];

const PartnerEvents: React.FC = () => (
	<Flex
		justifyContent={{ xs: "center", lg: "flex-start" }}
		pl={{ xs: "0%", lg: "10%", xl: "8%" }}
		bgColor="bg.lightWhite"
		py="5px"
	>
		<Box id="media-coverage" pt={{ xs: "60px" }} maxW="container.2xl" w="90%">
			<Heading
				p="0"
				fontSize={{ xs: "22px", sm: "28px", md: "30px", lg: "42px" }}
				lineHeight={{ xs: "30px", md: "40px", lg: "54.6px" }}
				fontWeight="800"
			>
				Partner Events
			</Heading>
			<SimpleGrid
				w="100%"
				mt="24px"
				gap="2rem"
				columns={{ xs: 1, md: 2, xl: 3 }}
			>
				{information?.map((info) => (
					<Box
						boxShadow="md"
						borderRadius="xl"
						overflow="hidden"
						key={info?.title}
					>
						<Img height="250px" w="100%" src={info.cover} alt={info.title} />
						<Box p="1rem">
							<Heading as="h3" variant="featureTitle">
								{info.title}
							</Heading>
							<Box
								overflowY="scroll"
								css={{
									"&::-webkit-scrollbar": {
										display: "none",
									},
								}}
							>
								{typeof info?.desc === "string" ? (
									<Text variant="featureText" textAlign="justify">
										{info?.desc}
									</Text>
								) : (
									info?.desc
								)}
							</Box>
							<Link href={info.link} aria-label="learn-more" target="_blank">
								<Button
									colorScheme="primary"
									aria-label="learn-more"
									w={{ xs: "175px" }}
									h={{ xs: "30px", md: "40px" }}
									mt="15px"
								>
									{info.btnText}
								</Button>
							</Link>
						</Box>
					</Box>
				))}
			</SimpleGrid>
		</Box>
	</Flex>
);

export default PartnerEvents;
