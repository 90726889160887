import Axios, { type AxiosError } from "axios";
import { errorToast } from "@/components/Toasts";
import { levoClient } from "@/levo/client";

export const levoAxios = Axios.create({
	baseURL: `${process.env.NEXT_PUBLIC_LEVO_CDN_API_URL}/v1`,
	headers: {
		"Content-Type": "application/json",
		"Levo-Workspace": process.env.NEXT_PUBLIC_LEVO_WORKSPACE,
	},
});

levoAxios.interceptors.request.use(
	(config) => {
		config.headers["Levo-Page-Context"] = JSON.stringify(
			levoClient.getPageContext(),
		);
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export const errorHandler = (err: AxiosError<any>) => {
	if (err.response?.data?.errors) {
		errorToast(err.response.data.errors[0].message);
	} else {
		errorToast("Something went wrong");
	}

	return err;
};

export type LevoResponseSingle<T, K = Record<string, any>> = {
	status: boolean;
	content: {
		data: T;
		meta?: K;
	};
};

export type LevoResponseList<
	T,
	K = {
		total: number;
		pages: number;
	},
> = {
	status: boolean;
	content: {
		data: T[];
		meta: K;
	};
};
