import { type ILevoConfig, LevoClient } from "@levo-so/core";
import { LevoAudience } from "@levo-so/core/audience-plugin";
import { LevoCollection } from "@levo-so/core/collection-plugin";
import { LevoMembership } from "@levo-so/core/membership-plugin";

export const levoClient = new LevoClient({
	workspace: "WGLBUT82",
	apiUrl: "/.levo/public/api",
	insightsUrl: "/.levo/insights/api",
	NODE_ENV: process.env.NODE_ENV,
	APP_MODE: process.env.NEXT_PUBLIC_APP_MODE as ILevoConfig["APP_MODE"],
});

export const levoMembership = new LevoMembership(levoClient);

export const levoAudience = new LevoAudience(levoClient);

export const levoCollection = new LevoCollection(levoClient);
